<template>
  <section class="page-container">
    <div class="container-wrapper">
      <product-list :show-pagination="true" :co-order="false"></product-list>
    </div>
  </section>
</template>

<script>
  import ProductList from "@/components/Product/List";
  import { onMounted, computed } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";
  import { useRoute } from "vue-router";

  export default {
    name: "Category",
    components: {
      ProductList,

    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const route = useRoute();
      let keyUrl = route.params?.category;
      store.dispatch("catalog/getCategory", keyUrl);

      const isCategorySelected = computed(() => {
        return (route.path.indexOf("kategorie") >= 0);
      });

      return {
        category: computed(() => {
          return store.getters["catalog/getCategory"];
        }),
        t,
        isCategorySelected
      };

    }
  };
</script>
<style lang="postcss" scoped>
  .page-container {
    @apply w-full bg-blue-lightest;
  }
  .container-wrapper {
    @apply w-11/12 ml-auto mr-auto justify-center items-center text-gray-dark;
    grid-area: main;
  }
  @media (min-width: 992px) {
    .container-wrapper {
      @apply w-9/12;
      width: 960px;
    }
  }
</style>
